import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { TiVendorApple } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { loginUser } from "../../redux/slices/authSlice"; // loginUser thunk'ı
import "./Login.scss";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("pages");
  const { loading, error } = useSelector((state) => state.auth); // Redux durumundan hata ve yükleme durumu

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      alert(t("login.errors.requiredFields"));
      return;
    }

    // Thunk'ı çağır
    const result = await dispatch(loginUser(formData));

    // Giriş başarılıysa yönlendirme yap
    if (result.meta.requestStatus === "fulfilled") {
      navigate("/enterance");
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>{t("login.title")}</h2>
        <p>{t("login.subtitle")}</p>
        {error && <div className="error-message">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="email">{t("login.labels.email")}</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={t("login.placeholders.email")}
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">{t("login.labels.password")}</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder={t("login.placeholders.password")}
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? t("login.buttons.loading") : t("login.buttons.submit")}
          </button>
        </form>

        <p className="or-text">{t("login.orText")}</p>

        <button className="social-login google-login">
          {t("login.buttons.googleLogin")} <FcGoogle />
        </button>
        <button className="social-login apple-login">
          {t("login.buttons.appleLogin")} <TiVendorApple />
        </button>

        <p className="register-link">
          {t("login.registerPrompt")} <a href="/register">{t("login.registerLink")}</a>
        </p>
      </div>
    </div>
  );
};

export default Login;
