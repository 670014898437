import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./PostJob.scss";
import { categories } from "../../assets/data/categories";
import { services } from "../../assets/data/services";
import { cities } from "../../assets/data/cities";
import { district } from "../../assets/data/district";
import { hourlywagelist } from "../../assets/data/hourlywagelist";
import { useTranslation } from "react-i18next";

const PostJob = () => {
  const { t } = useTranslation("pages");
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedHourlyWage, setSelectedHourlyWage] = useState("");
  const [description, setDescription] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedJob("");
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
    setSelectedDistrict("");
  };

  const filteredJobs = services.find((s) => s.name === selectedCategory)?.services || [];
  const filteredDistricts = district.filter((d) => d.il_id.toString() === selectedCity);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const postData = {
      userId: user?.userId,
      jobCategoryId: categories.find((c) => c.name === selectedCategory)?.id,
      jobServiceId: filteredJobs.find((j) => j.name === selectedJob)?.id,
      cityId: parseInt(selectedCity),
      districtId: parseInt(selectedDistrict),
      hourlyWage: parseInt(selectedHourlyWage),
      description,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/job/add`, postData);
      if (response.status === 200 || response.status === 201) {
        setSuccessMessage(t("postJob.successMessage"));
        setTimeout(() => navigate("/enterance"), 2000);
      }
    } catch (error) {
      console.error(t("postJob.errorMessage"), error);
      setSuccessMessage(t("postJob.errorMessage"));
    }
  };

  return (
    <div className="post-job">
      <h1>{t("postJob.title")}</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>{t("postJob.formLabels.category")}</label>
          <select value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">{t("postJob.formLabels.category")}</option>
            {categories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>{t("postJob.formLabels.job")}</label>
          <select
            value={selectedJob}
            onChange={(e) => setSelectedJob(e.target.value)}
            disabled={!selectedCategory}
          >
            <option value="">{t("postJob.formLabels.job")}</option>
            {filteredJobs.map((job) => (
              <option key={job.id} value={job.name}>
                {job.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>{t("postJob.formLabels.city")}</label>
          <select value={selectedCity} onChange={handleCityChange}>
            <option value="">{t("postJob.formLabels.city")}</option>
            {cities.map((city) => (
              <option key={city.il_id} value={city.il_id}>
                {city.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>{t("postJob.formLabels.district")}</label>
          <select
            value={selectedDistrict}
            onChange={(e) => setSelectedDistrict(e.target.value)}
            disabled={!selectedCity}
          >
            <option value="">{t("postJob.formLabels.district")}</option>
            {filteredDistricts.map((district) => (
              <option key={district.id} value={district.id}>
                {district.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>{t("postJob.formLabels.hourlyWage")}</label>
          <select value={selectedHourlyWage} onChange={(e) => setSelectedHourlyWage(e.target.value)}>
            <option value="">{t("postJob.formLabels.hourlyWage")}</option>
            {hourlywagelist.map((wage, index) => (
              <option key={index} value={wage}>
                {wage} TL
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>{t("postJob.formLabels.description")}</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t("postJob.formPlaceholders.description")}
          ></textarea>
        </div>

        <button type="submit" className="submit-button">
          {t("postJob.formButtons.submitJob")}
        </button>
      </form>
      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
};

export default PostJob;
