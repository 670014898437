import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { TiVendorApple } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Çeviri için
import "./Register.scss";

const baseUrl = process.env.REACT_APP_BASE_URL;

const endpoints = {
  register: `${baseUrl}/register`,
  googleRegister: `${baseUrl}/register/google`,
  appleRegister: `${baseUrl}/register/apple`,
};

const Register = () => {
  const { t } = useTranslation("pages"); // "pages" anahtarını kullanıyoruz
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isMaster: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      alert(t("register.messages.passwordMismatch"));
      return;
    }

    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      alert(t("register.messages.invalidEmail"));
      return;
    }

    if (formData.password.length < 6) {
      alert(t("register.messages.passwordTooShort"));
      return;
    }

    try {
      const response = await fetch(endpoints.register, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (data.success) {
        alert(t("register.messages.registrationSuccess"));
        setFormData({
          fullName: "",
          email: "",
          password: "",
          confirmPassword: "",
          isMaster: false,
        });
        navigate("/login");
      } else {
        alert(t("register.messages.error") + data.message);
      }
    } catch (error) {
      console.error(t("register.messages.error"), error);
    }
  };

  const handleGoogleRegister = async () => {
    try {
      const token = "osman"; // Örnek token
      const response = await fetch(endpoints.googleRegister, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      if (data.success) {
        alert(t("register.messages.googleSuccess"));
        navigate("/login");
      } else {
        alert(t("register.messages.error") + data.message);
      }
    } catch (error) {
      console.error(t("register.messages.error"), error);
    }
  };

  const handleAppleRegister = async () => {
    try {
      const token = "your-apple-auth-token"; // Örnek token
      const response = await fetch(endpoints.appleRegister, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      if (data.success) {
        alert(t("register.messages.appleSuccess"));
        navigate("/login");
      } else {
        alert(t("register.messages.error") + data.message);
      }
    } catch (error) {
      console.error(t("register.messages.error"), error);
    }
  };

  return (
    <div className="register-container">
      <div className="register-card">
        <h2>{t("register.title")}</h2>
        <p>{t("register.subtitle")}</p>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="fullName">{t("register.labels.fullName")}</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              placeholder={t("register.placeholders.fullName")}
              value={formData.fullName}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">{t("register.labels.email")}</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={t("register.placeholders.email")}
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">{t("register.labels.password")}</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder={t("register.placeholders.password")}
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="confirmPassword">{t("register.labels.confirmPassword")}</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder={t("register.placeholders.confirmPassword")}
              value={formData.confirmPassword}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="register-button">
            {t("register.buttons.createAccount")}
          </button>
        </form>

        <button onClick={handleGoogleRegister} className="social-register-button google">
          {t("register.buttons.googleRegister")} <span className="icons icons-google"><FcGoogle /></span>
        </button>
        <button onClick={handleAppleRegister} className="social-register-button apple">
          {t("register.buttons.appleRegister")} <span className="icons icons-apple"><TiVendorApple /></span>
        </button>

        <p className="login-link">
          {t("register.messages.loginLink")} <a href="/login">{t("register.labels.login")}</a>
        </p>
      </div>
    </div>
  );
};

export default Register;
