export const categories = [
  { id: 1, 
    name: "Temizlik Hizmetleri", 
    services: [] },
  { id: 2, 
    name: "Tadilat, Dekorasyon ve İnşaat Hizmetleri", 
    services: [] },
  { id: 3, 
    name: "Nakliyat ve Depolama Hizmetleri", 
    services: [] },
  { id: 4, 
    name: "Tüm Montaj ve Tamir Hizmetleri", 
    services: [] },
  { id: 5, 
    name: "Özel Ders Hizmetleri", 
    services: [] },
  { id: 6, 
    name: "Sağlık ve Güzellik Hizmetleri", 
    services: [] },
  { id: 7, 
    name: "Organizasyon Hizmetleri", 
    services: [] },
  { id: 8, 
    name: "Fotoğraf ve Video", 
    services: [] },
  { id: 9, 
    name: "Dijital ve Kurumsal", 
    services: [] },
  { id: 10, 
    name: "Evcil Hayvanlar", 
    services: [] },
  { id: 11, 
    name: "Oto ve Araç", 
    services: [] },
  { id: 12, 
    name: "Diğer", 
    services: [] },
];