import { Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.scss";
import Routers from "./routers/Routers";
import Navbar from "./components/Navbar/Navbar";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Fragment>
      <BrowserRouter>
        {isAuthenticated && <Navbar />}
        <Routers />
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
