export const cities = [
    { 
      il_id: 1, 
      name: "ADANA" 
    },
    { 
      il_id: 2, 
      name: "ADIYAMAN" 
    },
    { 
      il_id: 3, 
      name: "AFYONKARAHİSAR" 
    },
    { 
      il_id: 4, 
      name: "AĞRI" 
    },
    { 
      il_id: 5, 
      name: "AMASYA" 
    },
    { 
      il_id: 6, 
      name: "ANKARA" 
    },
    { 
      il_id: 7, 
      name: "ANTALYA" 
    },
    { 
      il_id: 8, 
      name: "ARTVİN" 
    },
    { 
      il_id: 9, 
      name: "AYDIN" 
    },
    { 
      il_id: 10, 
      name: "BALIKESİR" 
    },
    { 
      il_id: 11, 
      name: "BİLECİK" 
    },
    { 
      il_id: 12, 
      name: "BİNGÖL" 
    },
    { 
      il_id: 13, 
      name: "BİTLİS" 
    },
    { 
      il_id: 14, 
      name: "BOLU" 
    },
    { 
      il_id: 15, 
      name: "BURDUR" 
    },
    { 
      il_id: 16, 
      name: "BURSA" 
    },
    { 
      il_id: 17, 
      name: "ÇANAKKALE" 
    },
    { 
      il_id: 18, 
      name: "ÇANKIRI" 
    },
    { 
      il_id: 19, 
      name: "ÇORUM" 
    },
    { 
      il_id: 20, 
      name: "DENİZLİ" 
    },
    { 
      il_id: 21, 
      name: "DİYARBAKIR" 
    },
    { 
      il_id: 22, 
      name: "EDİRNE" 
    },
    { 
      il_id: 23, 
      name: "ELAZIĞ" 
    },
    { 
      il_id: 24, 
      name: "ERZİNCAN" 
    },
    { 
      il_id: 25, 
      name: "ERZURUM" 
    },
    { 
      il_id: 26, 
      name: "ESKİŞEHİR" 
    },
    { 
      il_id: 27, 
      name: "GAZİANTEP" 
    },
    { 
      il_id: 28, 
      name: "GİRESUN" 
    },
    { 
      il_id: 29, 
      name: "GÜMÜŞHANE" 
    },
    { 
      il_id: 30, 
      name: "HAKKARİ" 
    },
    { 
      il_id: 31, 
      name: "HATAY" 
    },
    { 
      il_id: 32, 
      name: "ISPARTA" 
    },
    { 
      il_id: 33, 
      name: "MERSİN" 
    },
    { 
      il_id: 34, 
      name: "İSTANBUL" 
    },
    { 
      il_id: 35, 
      name: "İZMİR" 
    },
    { 
      il_id: 36, 
      name: "KARS" 
    },
    { 
      il_id: 37, 
      name: "KASTAMONU" 
    },
    { 
      il_id: 38, 
      name: "KAYSERİ" 
    },
    { 
      il_id: 39, 
      name: "KIRKLARELİ" 
    },
    { 
      il_id: 40, 
      name: "KIRŞEHİR" 
    },
    { 
      il_id: 41, 
      name: "KOCAELİ" 
    },
    { 
      il_id: 42, 
      name: "KONYA" 
    },
    { 
      il_id: 43, 
      name: "KÜTAHYA" 
    },
    { 
      il_id: 44, 
      name: "MALATYA" 
    },
    { 
      il_id: 45, 
      name: "MANİSA" 
    },
    { 
      il_id: 46, 
      name: "KAHRAMANMARAŞ" 
    },
    { 
      il_id: 47, 
      name: "MARDİN" 
    },
    { 
      il_id: 48, 
      name: "MUĞLA" 
    },
    { 
      il_id: 49, 
      name: "MUŞ" 
    },
    { 
      il_id: 50, 
      name: "NEVŞEHİR" 
    },
    { 
      il_id: 51, 
      name: "NİĞDE" 
    },
    { 
      il_id: 52, 
      name: "ORDU" 
    },
    { 
      il_id: 53, 
      name: "RİZE" 
    },
    { 
      il_id: 54, 
      name: "SAKARYA" 
    },
    { 
      il_id: 55, 
      name: "SAMSUN" 
    },
    { 
      il_id: 56, 
      name: "SİİRT" 
    },
    { 
      il_id: 57, 
      name: "SİNOP" 
    },
    { 
      il_id: 58, 
      name: "SİVAS" 
    },
    { 
      il_id: 59, 
      name: "TEKİRDAĞ" 
    },
    { 
      il_id: 60, 
      name: "TOKAT" 
    },
    { 
      il_id: 61, 
      name: "TRABZON" 
    },
    { 
      il_id: 62, 
      name: "TUNCELİ" 
    },
    { 
      il_id: 63, 
      name: "ŞANLIURFA" 
    },
    { 
      il_id: 64, 
      name: "UŞAK" 
    },
    { 
      il_id: 65, 
      name: "VAN" 
    },
    { 
      il_id: 66, 
      name: "YOZGAT" 
    },
    { 
      il_id: 67, 
      name: "ZONGULDAK" 
    },
    { 
      il_id: 68, 
      name: "AKSARAY" 
    },
    { 
      il_id: 69, 
      name: "BAYBURT" 
    },
    { 
      il_id: 70, 
      name: "KARAMAN" 
    },
    { 
      il_id: 71, 
      name: "KIRIKKALE" 
    },
    { 
      il_id: 72, 
      name: "BATMAN" 
    },
    { 
      il_id: 73, 
      name: "ŞIRNAK" 
    },
    { 
      il_id: 74, 
      name: "BARTIN" 
    },
    { 
      il_id: 75, 
      name: "ARDAHAN" 
    },
    { 
      il_id: 76, 
      name: "IĞDIR" 
    },
    { 
      il_id: 77, 
      name: "YALOVA" 
    },
    { 
      il_id: 78, 
      name: "KARABÜK" 
    },
    { 
      il_id: 79, 
      name: "KİLİS" 
    },
    { 
      il_id: 80, 
      name: "OSMANİYE" 
    },
    { 
      il_id: 81, 
      name: "DÜZCE" 
    },
  ];
  