import React from "react";
import { useTranslation } from "react-i18next";
import "./Enterance.scss";
import { Link } from "react-router-dom";

const Enterance = () => {
  const { t } = useTranslation("pages");

  return (
    <div className="main-enterance">
      <div className="box-container">
        <Link to="/find-job" className="buttons search-job">
          <h2>{t("enterance.buttons.findJob")}</h2>
        </Link>
        <h1>{t("enterance.title")}</h1>
        <Link to="/post-job" className="buttons post-job-main">
          <h2>{t("enterance.buttons.postJob")}</h2>
        </Link>
      </div>
    </div>
  );
};

export default Enterance;
