import React, { useState } from "react";
import axios from "axios";
import "./FindJob.scss";
import { categories } from "../../assets/data/categories";
import { services } from "../../assets/data/services";
import { cities } from "../../assets/data/cities";
import { district } from "../../assets/data/district";
import { useTranslation } from "react-i18next";

const FindJob = () => {
  const { t } = useTranslation("pages");
  const [step, setStep] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const filteredJobs = services.find((s) => s.name === selectedCategory)?.services || [];
  const filteredDistricts = district.filter((d) => d.il_id.toString() === selectedCity);

  const nextStep = () => setStep((prev) => Math.min(prev + 1, 4));
  const prevStep = () => setStep((prev) => Math.max(prev - 1, 1));

  const handleSearch = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.post(`${baseUrl}/search`, {
        jobCategoryId: categories.find((c) => c.name === selectedCategory)?.id,
        jobServiceId: filteredJobs.find((j) => j.name === selectedJob)?.id,
        cityId: parseInt(selectedCity),
        districtId: parseInt(selectedDistrict),
      });

      if (response.data.success && response.data.result) {
        setResults(response.data.result);
      } else {
        setResults([]);
      }
    } catch (err) {
      setError(t("findJob.errors.search"));
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="find-job">
      <h1>{t("findJob.title")}</h1>
      <div className="stepper">
        <div className={`step ${step >= 1 ? "active" : ""}`}>{t("findJob.steps.step1")}</div>
        <div className={`step ${step >= 2 ? "active" : ""}`}>{t("findJob.steps.step2")}</div>
        <div className={`step ${step >= 3 ? "active" : ""}`}>{t("findJob.steps.step3")}</div>
        <div className={`step ${step >= 4 ? "active" : ""}`}>{t("findJob.steps.step4")}</div>
      </div>

      <div className="form">
        {step === 1 && (
          <div className="form-group">
            <label>{t("findJob.formLabels.category")}</label>
            <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
              <option value="">{t("findJob.formPlaceholders.selectCategory")}</option>
              {categories.map((category) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {step === 2 && (
          <div className="form-group">
            <label>{t("findJob.formLabels.job")}</label>
            <select value={selectedJob} onChange={(e) => setSelectedJob(e.target.value)} disabled={!selectedCategory}>
              <option value="">{t("findJob.formPlaceholders.selectJob")}</option>
              {filteredJobs.map((job) => (
                <option key={job.id} value={job.name}>
                  {job.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {step === 3 && (
          <>
            <div className="form-group">
              <label>{t("findJob.formLabels.city")}</label>
              <select value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)}>
                <option value="">{t("findJob.formPlaceholders.selectCity")}</option>
                {cities.map((city) => (
                  <option key={city.il_id} value={city.il_id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>{t("findJob.formLabels.district")}</label>
              <select
                value={selectedDistrict}
                onChange={(e) => setSelectedDistrict(e.target.value)}
                disabled={!selectedCity}
              >
                <option value="">{t("findJob.formPlaceholders.selectDistrict")}</option>
                {filteredDistricts.map((district) => (
                  <option key={district.id} value={district.id}>
                    {district.name}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}

        {step === 4 && (
          <div className="results">
            <h2>{t("findJob.results.title")}</h2>
            {loading ? (
              <p>{t("findJob.results.loading")}</p>
            ) : error ? (
              <p className="error">{error}</p>
            ) : results.length > 0 ? (
              results.map((job) => (
                <div key={job._id} className="result">
                  <img
                    src={`${baseUrl}/uploads/${job.profilePicture}`}
                    alt={job.fullName}
                    className="profile-picture"
                  />
                  <div className="details">
                    <h3>{job.jobService}</h3>
                    <p>
                      {job.city}, {job.district}
                    </p>
                    <p>{job.description}</p>
                    <p>
                      <strong>₺{job.hourlyWage}</strong> / {t("findJob.results.hour")}
                    </p>
                    <p>
                      <em>
                        {t("findJob.results.owner")}: {job.fullName}
                      </em>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p>{t("findJob.results.noResults")}</p>
            )}
          </div>
        )}
      </div>

      <div className="buttons">
        {step > 1 && <button onClick={prevStep}>{t("findJob.buttons.prev")}</button>}
        {step < 4 && (
          <button onClick={nextStep} disabled={(step === 1 && !selectedCategory) || (step === 2 && !selectedJob)}>
            {t("findJob.buttons.next")}
          </button>
        )}
        {step === 4 && <button onClick={handleSearch}>{t("findJob.buttons.search")}</button>}
      </div>
    </div>
  );
};

export default FindJob;
