export const services = [
    {
      "id": 1,
      "name": "Temizlik Hizmetleri",
      "services": [
        { "id": 101, "name": "Apartman Temizliği" },
        { "id": 102, "name": "Bilgisayar Temizliği" },
        { "id": 103, "name": "Böcek İlaçlama" },
        { "id": 104, "name": "Boş Ev Temizliği" },
        { "id": 105, "name": "Cam Silme" },
        { "id": 106, "name": "Cam Temizliği" },
        { "id": 107, "name": "Dış Cephe Cam Silme" },
        { "id": 108, "name": "Dükkan Temizliği" },
        { "id": 109, "name": "Ev İlaçlama" },
        { "id": 110, "name": "Ev Temizliği" },
        { "id": 111, "name": "Evde Halı Yıkama" },
        { "id": 112, "name": "Evde Koltuk Yıkama" },
        { "id": 113, "name": "Evde Ütü Hizmeti" },
        { "id": 114, "name": "Evde Yemek Pişirme" },
        { "id": 115, "name": "Fare İlaçlama" },
        { "id": 116, "name": "Gümüş Böceği İlaçlama" },
        { "id": 117, "name": "Halı Yıkama" },
        { "id": 118, "name": "Halıfleks Yıkama" },
        { "id": 119, "name": "Hamam Böceği İlaçlama" },
        { "id": 120, "name": "Haşere İlaçlama" },
        { "id": 121, "name": "İnşaat Sonrası Temizlik" },
        { "id": 122, "name": "Kalorifer Böceği İlaçlama" },
        { "id": 123, "name": "Karınca İlaçlama" },
        { "id": 124, "name": "Klima Temizliği" },
        { "id": 125, "name": "Koltuk Yıkama" },
        { "id": 126, "name": "Kombi Petek Temizleme" },
        { "id": 127, "name": "Kuru Temizleme" },
        { "id": 128, "name": "Mağaza Cam Temizliği" },
        { "id": 129, "name": "Merdiven Temizliği" },
        { "id": 130, "name": "Mermer Cilalama" },
        { "id": 131, "name": "Mermer Silim" },
        { "id": 132, "name": "Ofis Halı Yıkama" },
        { "id": 133, "name": "Ofis Temizliği" },
        { "id": 134, "name": "Petek Temizleme" },
        { "id": 135, "name": "Petek Temizliği" },
        { "id": 136, "name": "Pire İlaçlama" },
        { "id": 137, "name": "Stor Perde Yıkama" },
        { "id": 138, "name": "Su Deposu Temizliği" },
        { "id": 139, "name": "Tahta Kurusu İlaçlama" },
        { "id": 140, "name": "Yaprak Sarma Yapımı" },
        { "id": 141, "name": "Yatak Yıkama" },
        { "id": 142, "name": "Yerinde Araç Koltuk Yıkama" }
      ]
    },
    {
      "id": 2,
      "name": "Tadilat, Dekorasyon ve İnşaat Hizmetleri",
      "services": [
        { "id": 201, "name": "Ahşap Kapı" },
        { "id": 202, "name": "Alçı Ustası" },
        { "id": 203, "name": "Alçıpan Asma Tavan" },
        { "id": 204, "name": "Alçıpan Bölme Duvar" },
        { "id": 205, "name": "Amerikan Panel Kapı" },
        { "id": 206, "name": "Anahtar Teslim İnşaat" },
        { "id": 207, "name": "Asma Tavan" },
        { "id": 208, "name": "Bahçe Bakımı" },
        { "id": 209, "name": "Bahçe Çit Teli" },
        { "id": 210, "name": "Bahçe Çiti" },
        { "id": 211, "name": "Bahçe Duvarı Yapımı" },
        { "id": 212, "name": "Bahçe Düzenleme" },
        { "id": 213, "name": "Bahçe Teli" },
        { "id": 214, "name": "Bahçıvan" },
        { "id": 215, "name": "Balkon Filesi" },
        { "id": 216, "name": "Balkon Kedi Filesi" },
        { "id": 217, "name": "Banyo Dolabı Yapımı" },
        { "id": 218, "name": "Banyo Fayans Döşeme" },
        { "id": 219, "name": "Banyo Tadilat" },
        { "id": 220, "name": "Boya Badana" },
        { "id": 221, "name": "Cam Balkon" },
        { "id": 222, "name": "Çatı İzolasyon" },
        { "id": 223, "name": "Çatı Tadilatı" },
        { "id": 224, "name": "Çatı Tamiri" },
        { "id": 225, "name": "Çelik Kapı" },
        { "id": 226, "name": "Demir Kaynak" },
        { "id": 227, "name": "Dış Cephe Kaplama" },
        { "id": 228, "name": "Doğalgaz Tesisatı" },
        { "id": 229, "name": "Dolap Boyama" },
        { "id": 230, "name": "Duşakabin" },
        { "id": 231, "name": "Duvar Dekorasyonu" },
        { "id": 232, "name": "Duvar Kağıdı Döşeme" },
        { "id": 233, "name": "Duvar Kaplama" },
        { "id": 234, "name": "Ev Boyama" },
        { "id": 235, "name": "Ev Dekorasyon" },
        { "id": 236, "name": "Ev Tadilat" },
        { "id": 237, "name": "Fayans Döşeme" },
        { "id": 238, "name": "Folyo Kaplama" },
        { "id": 239, "name": "Gardrop Yapımı" },
        { "id": 240, "name": "Granit" },
        { "id": 241, "name": "Güneş Enerjisi" },
        { "id": 242, "name": "Havuz Yapımı" },
        { "id": 243, "name": "Hazır Rulo Çim" },
        { "id": 244, "name": "Hurdacı" },
        { "id": 245, "name": "İç Kapı" },
        { "id": 246, "name": "İç Mimar" },
        { "id": 247, "name": "Kaba İnşaat" },
        { "id": 248, "name": "Kağıt Hurdacı" },
        { "id": 249, "name": "Kanepe Döşeme" },
        { "id": 250, "name": "Kapı Sinekliği" },
        { "id": 251, "name": "Katlanır Cam Balkon" },
        { "id": 252, "name": "Kaynak" },
        { "id": 253, "name": "Kedi Filesi" },
        { "id": 254, "name": "Kedi Pencere Sinekliği" },
        { "id": 255, "name": "Kedi Sinekliği" },
        { "id": 256, "name": "Kepenk" },
        { "id": 257, "name": "Koltuk Döşeme" },
        { "id": 258, "name": "Koltuk Kaplama" },
        { "id": 259, "name": "L Koltuk Kaplama" },
        { "id": 260, "name": "Laminat Parke Döşeme" },
        { "id": 261, "name": "Mantolama" },
        { "id": 262, "name": "Marangoz" },
        { "id": 263, "name": "Menteşeli Pencere Sinekliği" },
        { "id": 264, "name": "Mermer" },
        { "id": 265, "name": "Mermer Mutfak Tezgahı" },
        { "id": 266, "name": "Mermer Tezgah" },
        { "id": 267, "name": "Mezar Yapımı" },
        { "id": 268, "name": "Mobilya Boyama" },
        { "id": 269, "name": "Mutfak Dekorasyon" },
        { "id": 270, "name": "Mutfak Dolabı Boyama" },
        { "id": 271, "name": "Mutfak Dolabı Yapımı" },
        { "id": 272, "name": "Mutfak Tadilat" },
        { "id": 273, "name": "Mutfak Tezgahı" },
        { "id": 274, "name": "Otomatik Kapı" },
        { "id": 275, "name": "Otomatik Kepenk" },
        { "id": 276, "name": "Otomatik Panjur" },
        { "id": 277, "name": "Panel Çit" },
        { "id": 278, "name": "Panjur" },
        { "id": 279, "name": "Parke Laminat Döşeme" },
        { "id": 280, "name": "Parke Sistre Cila" },
        { "id": 281, "name": "Parke Taşı Döşeme" },
        { "id": 282, "name": "Pencere Sinekliği" },
        { "id": 283, "name": "Pimapen Kapı" },
        { "id": 284, "name": "Pimapen Pencere" },
        { "id": 285, "name": "Prefabrik Ev Yapımı" },
        { "id": 286, "name": "PVC Pencere" },
        { "id": 287, "name": "Sandalye Döşeme" },
        { "id": 288, "name": "Sineklik" },
        { "id": 289, "name": "Sıva Ustası" },
        { "id": 290, "name": "Stor Perde" },
        { "id": 291, "name": "Su İzolasyonu" },
        { "id": 292, "name": "Su Yalıtımı" },
        { "id": 293, "name": "Süpürgelik Montajı" },
        { "id": 294, "name": "Sürgülü Kapı" },
        { "id": 295, "name": "Sürgülü Pencere Sineklik" },
        { "id": 296, "name": "Sürgülü Sineklik" },
        { "id": 297, "name": "Tamirat Tadilat" },
        { "id": 298, "name": "Tekli Koltuk Kaplama" },
        { "id": 299, "name": "Tel Örgü Çit" },
        { "id": 300, "name": "Tente Branda" }
      ]
    },
    {
      "id": 3,
      "name": "Nakliyat ve Depolama Hizmetleri",
      "services": [
        { "id": 301, "name": "Almanyaya Ev Taşıma" },
        { "id": 302, "name": "Ambar Kargo" },
        { "id": 303, "name": "Araç Taşıma" },
        { "id": 304, "name": "Asansör Kiralama" },
        { "id": 305, "name": "Asansörlü Nakliyat" },
        { "id": 306, "name": "Bulaşık Makinesi Taşıma" },
        { "id": 307, "name": "Buzdolabı Taşıma" },
        { "id": 308, "name": "Çamaşır Makinesi Taşıma" },
        { "id": 309, "name": "Çeyiz Taşıma" },
        { "id": 310, "name": "Depo Kiralama" },
        { "id": 311, "name": "Doblo Nakliye" },
        { "id": 312, "name": "Düğün Servis Kiralama" },
        { "id": 313, "name": "Düğün Servisi" },
        { "id": 314, "name": "Eczane Kurye" },
        { "id": 315, "name": "Eşya Depolama" },
        { "id": 316, "name": "Eşya Taşıma" },
        { "id": 317, "name": "Ev İçi Eşya Taşıma" },
        { "id": 318, "name": "Ev Taşıma" },
        { "id": 319, "name": "Evden Eve Nakliyat" },
        { "id": 320, "name": "Günlük Şoför" },
        { "id": 321, "name": "Hamal" },
        { "id": 322, "name": "Havaalanı Transfer" },
        { "id": 323, "name": "Kamyonet Kiralama" },
        { "id": 324, "name": "Kamyonet Nakliye" },
        { "id": 325, "name": "Kedi Taşıma" },
        { "id": 326, "name": "Kısa Mesafe Nakliye" },
        { "id": 327, "name": "Koli Taşıma" },
        { "id": 328, "name": "Koltuk Taşıma" },
        { "id": 329, "name": "Köpek Taşıma" },
        { "id": 330, "name": "Minibüs Kiralama" },
        { "id": 331, "name": "Minivan Nakliye" },
        { "id": 332, "name": "Moto Kurye" },
        { "id": 333, "name": "Motosiklet Çekici" },
        { "id": 334, "name": "Motosiklet Taşıma" },
        { "id": 335, "name": "Ofis Taşıma" },
        { "id": 336, "name": "Oto Çekici" },
        { "id": 337, "name": "Oto Kurtarma" },
        { "id": 338, "name": "Otobüs Kiralama" },
        { "id": 339, "name": "Özel Ambulans" },
        { "id": 340, "name": "Özel Şoför" },
        { "id": 341, "name": "Paletli Yük Taşıma" },
        { "id": 342, "name": "Panelvan Nakliye" },
        { "id": 343, "name": "Personel Servisi" },
        { "id": 344, "name": "Pikap Nakliye" },
        { "id": 345, "name": "Şehir İçi Nakliye" },
        { "id": 346, "name": "Şehirler Arası Ambulans" },
        { "id": 347, "name": "Şehirler Arası Araç Taşıma" },
        { "id": 348, "name": "Şehirler Arası Eşya Taşıma" },
        { "id": 349, "name": "Şehirler Arası Motosiklet Taşıma" },
        { "id": 350, "name": "Şehirler Arası Nakliye" },
        { "id": 351, "name": "Şehirler Arası Taksi" },
        { "id": 352, "name": "Şehirler Arası Yük Taşıma" },
        { "id": 353, "name": "Şöförlü Araç Kiralama" },
        { "id": 354, "name": "Şoförsüz Kiralık Kamyonet" },
        { "id": 355, "name": "Taksi" },
        { "id": 356, "name": "Tekne Taşıma" },
        { "id": 357, "name": "Transfer" },
        { "id": 358, "name": "Uluslararası Ev Taşıma" },
        { "id": 359, "name": "Uluslararası Evden Eve Nakliyat" },
        { "id": 360, "name": "Uluslararası Nakliyat" },
        { "id": 361, "name": "Vip Transfer" },
        { "id": 362, "name": "Vito Kiralama" },
        { "id": 363, "name": "Yük Taşıma" },
        { "id": 364, "name": "Yurtdışı Kargo" },
        { "id": 365, "name": "Yurtdışına Ev Taşıma" }
      ]
    },
    {
      "id": 4,
      "name": "Tüm Montaj ve Tamir Hizmetleri",
      "services": [
        { "id": 401, "name": "Ahşap Kapı Tamiri" },
        { "id": 402, "name": "Airfel Kombi Servisi" },
        { "id": 403, "name": "Alüminyum Doğrama" },
        { "id": 404, "name": "Amerikan Panel Kapı Montajı" },
        { "id": 405, "name": "Amerikan Panel Kapı Tamiri" },
        { "id": 406, "name": "Arçelik Buzdolabı Servisi" },
        { "id": 407, "name": "Arçelik Klima Servisi" },
        { "id": 408, "name": "Arçelik Servisi" },
        { "id": 409, "name": "Asansör Bakım" },
        { "id": 410, "name": "Avize Montaj" },
        { "id": 411, "name": "Ayakkabı Tamiri" },
        { "id": 412, "name": "Baymak Kombi Servisi" },
        { "id": 413, "name": "Beyaz Eşya Servisi" },
        { "id": 414, "name": "Beyaz Eşya Tamiri" },
        { "id": 415, "name": "Bilgisayar Tamiri" },
        { "id": 416, "name": "Bilgisayarcı" },
        { "id": 417, "name": "Bosch Kombi Servisi" },
        { "id": 418, "name": "Buderus Kombi Servisi" },
        { "id": 419, "name": "Bulaşık Makinesi Tamiri" },
        { "id": 420, "name": "Buzdolabı Servisi" },
        { "id": 421, "name": "Buzdolabı Tamiri" },
        { "id": 422, "name": "Çamaşır Makinesi Tamiri" },
        { "id": 423, "name": "Camcı" },
        { "id": 424, "name": "Çanak Anten Ayarlama" },
        { "id": 425, "name": "Çanak Anten Kurulumu" },
        { "id": 426, "name": "Çelik Kapı Tamiri" },
        { "id": 427, "name": "Çilingir" },
        { "id": 428, "name": "Çilingir Kapı Açma" },
        { "id": 429, "name": "Demirdöküm Kombi Servisi" },
        { "id": 430, "name": "Dolap Montajı" },
        { "id": 431, "name": "Duşakabin Cam Değişimi" },
        { "id": 432, "name": "Duşakabin Montajı" },
        { "id": 433, "name": "Duşakabin Tamiri" },
        { "id": 434, "name": "ECA Kombi Servisi" },
        { "id": 435, "name": "Electrolux Servisi" },
        { "id": 436, "name": "Elektrik" },
        { "id": 437, "name": "Elektrik Hattı Çekme" },
        { "id": 438, "name": "Elektrik Montaj" },
        { "id": 439, "name": "Elektrik Tamiri" },
        { "id": 440, "name": "Elektrikçi" },
        { "id": 441, "name": "Franke Servisi" },
        { "id": 442, "name": "Gardrop Montajı" },
        { "id": 443, "name": "Görüntülü Diafon" },
        { "id": 444, "name": "İnternet Kablosu Çekme" },
        { "id": 445, "name": "iPhone Tamiri" },
        { "id": 446, "name": "IPTV Kurulumu" },
        { "id": 447, "name": "Kablo ve Hat Çekme" },
        { "id": 448, "name": "Kamera Güvenlik Sistemleri" },
        { "id": 449, "name": "Kamera Sistemleri" },
        { "id": 450, "name": "Klima Bakım" },
        { "id": 451, "name": "Klima Elektrik Bağlantısı" },
        { "id": 452, "name": "Klima Gaz Dolumu" },
        { "id": 453, "name": "Klima Montaj" },
        { "id": 454, "name": "Klima Servisi" },
        { "id": 455, "name": "Klima Soğutmuyor" },
        { "id": 456, "name": "Klima Sökme" },
        { "id": 457, "name": "Klima Tamiri" },
        { "id": 458, "name": "Klozet Montajı" },
        { "id": 459, "name": "Klozet Su Kaçırıyor" },
        { "id": 460, "name": "Kombi Bakım" },
        { "id": 461, "name": "Kombi Montaj" },
        { "id": 462, "name": "Kombi Servisi" },
        { "id": 463, "name": "Kombi Tamiri" },
        { "id": 464, "name": "Korniş Montaj" },
        { "id": 465, "name": "LG TV Servisi" },
        { "id": 466, "name": "Mitsubishi Klima Servisi" },
        { "id": 467, "name": "Mobilya Montaj" },
        { "id": 468, "name": "Mobilya Sökme Taşıma ve Montaj" },
        { "id": 469, "name": "Musluk Değiştirme" },
        { "id": 470, "name": "Musluk Tamiri" },
        { "id": 471, "name": "Mutfak Dolap Kapağı Değiştirme" },
        { "id": 472, "name": "Oda Kapısı" },
        { "id": 473, "name": "Panjur Tamiri" },
        { "id": 474, "name": "Pencere Camı Değişimi" },
        { "id": 475, "name": "Perde Korniş Takma" },
        { "id": 476, "name": "Pimapen Kapı Tamiri" },
        { "id": 477, "name": "Pimapen Pencere Tamiri" },
        { "id": 478, "name": "Robot Süpürge Tamiri" },
        { "id": 479, "name": "Saat Tamiri" },
        { "id": 480, "name": "Samsung TV Servisi" },
        { "id": 481, "name": "Sifon Tamiri" },
        { "id": 482, "name": "Sıhhi Tesisat" },
        { "id": 483, "name": "Su Arıtma Filtre Değişimi" },
        { "id": 484, "name": "Su Kaçağı Tamiri" },
        { "id": 485, "name": "Su Kaçağı Tespiti" },
        { "id": 486, "name": "Su Tesisatçısı" },
        { "id": 487, "name": "Su Tesisatı Döşeme" },
        { "id": 488, "name": "Su Tesisatı Montaj" },
        { "id": 489, "name": "Sürgülü Dolap Tamiri" },
        { "id": 490, "name": "Telefon Tamiri" },
        { "id": 491, "name": "Tıkanıklık Açma" },
        { "id": 492, "name": "TV Ekran Tamiri" },
        { "id": 493, "name": "TV LED Değişimi" },
        { "id": 494, "name": "TV Montajı" },
        { "id": 495, "name": "TV Panel Tamiri" },
        { "id": 496, "name": "TV Tamiri" },
        { "id": 497, "name": "Uydu Ayarlama" },
        { "id": 498, "name": "Uyducu" },
        { "id": 499, "name": "Vaillant Kombi Servisi" },
        { "id": 500, "name": "Veri Kurtarma" }
      ]
    }  ,
    {
      "id": 5,
      "name": "Özel Ders Hizmetleri",
      "services": [
        { "id": 501, "name": "Almanca Özel Ders" },
        { "id": 502, "name": "Arapça Özel Ders" },
        { "id": 503, "name": "AYT Matematik Özel Ders" },
        { "id": 504, "name": "Bağlama Dersi" },
        { "id": 505, "name": "Barista Eğitimi" },
        { "id": 506, "name": "Basketbol Özel Ders" },
        { "id": 507, "name": "Bateri Dersi" },
        { "id": 508, "name": "Boks Dersi" },
        { "id": 509, "name": "Çocuk Piyano Dersi" },
        { "id": 510, "name": "DGS Matematik Özel Ders" },
        { "id": 511, "name": "Diksiyon Dersi" },
        { "id": 512, "name": "Direksiyon Dersi" },
        { "id": 513, "name": "Düğün Dans Dersi" },
        { "id": 514, "name": "Eğitim Koçu" },
        { "id": 515, "name": "Ehliyet Kursu" },
        { "id": 516, "name": "Fen Bilimleri Özel Ders" },
        { "id": 517, "name": "Fitness Özel Ders" },
        { "id": 518, "name": "Fizik Özel Ders" },
        { "id": 519, "name": "Fransızca Özel Ders" },
        { "id": 520, "name": "Futbol Özel Ders" },
        { "id": 521, "name": "Gitar Dersi" },
        { "id": 522, "name": "Grup Pilates Dersi" },
        { "id": 523, "name": "Hızlı Okuma Dersi" },
        { "id": 524, "name": "İlkokul Matematik Özel Ders" },
        { "id": 525, "name": "İlkokul Özel Ders" },
        { "id": 526, "name": "İngilizce Özel Ders" },
        { "id": 527, "name": "İspanyolca Özel Ders" },
        { "id": 528, "name": "Keman Dersi" },
        { "id": 529, "name": "Kick Boks Dersi" },
        { "id": 530, "name": "Kimya Özel Ders" },
        { "id": 531, "name": "KPSS Koçu" },
        { "id": 532, "name": "KPSS Matematik Özel Ders" },
        { "id": 533, "name": "Kuran Özel Ders" },
        { "id": 534, "name": "LGS Matematik Özel Ders" },
        { "id": 535, "name": "Lise Matematik Özel Ders" },
        { "id": 536, "name": "Matematik Özel Ders" },
        { "id": 537, "name": "Motor Ehliyet Kursu" },
        { "id": 538, "name": "Motosiklet Eğitimi" },
        { "id": 539, "name": "Ödev Ablası" },
        { "id": 540, "name": "Öğrenci Koçu" },
        { "id": 541, "name": "Okuma Yazma Özel Ders" },
        { "id": 542, "name": "Online Almanca Özel Ders" },
        { "id": 543, "name": "Online Arapça Özel Ders" },
        { "id": 544, "name": "Online Diksiyon Dersi" },
        { "id": 545, "name": "Online Eğitim Koçu" },
        { "id": 546, "name": "Online Fizik Özel Ders" },
        { "id": 547, "name": "Online Fransızca Özel Ders" },
        { "id": 548, "name": "Online Gitar Dersi" },
        { "id": 549, "name": "Online IELTS Özel Ders" },
        { "id": 550, "name": "Online İlkokul Matematik Özel Ders" },
        { "id": 551, "name": "Online İlkokul Özel Ders" },
        { "id": 552, "name": "Online İngilizce Konuşma" },
        { "id": 553, "name": "Online İngilizce Özel Ders" },
        { "id": 554, "name": "Online İspanyolca Özel Ders" },
        { "id": 555, "name": "Online İtalyanca Özel Ders" },
        { "id": 556, "name": "Online Kuran Özel Ders" },
        { "id": 557, "name": "Online LGS Matematik Özel Ders" },
        { "id": 558, "name": "Online Lise Matematik Özel Ders" },
        { "id": 559, "name": "Online Muhasebe Dersi" },
        { "id": 560, "name": "Online Öğrenci Koçu" },
        { "id": 561, "name": "Online Ortaokul Matematik Özel Ders" },
        { "id": 562, "name": "Online Personal Trainer" },
        { "id": 563, "name": "Online Pilates Dersi" },
        { "id": 564, "name": "Online Resim Dersi" },
        { "id": 565, "name": "Online Rusça Özel Ders" },
        { "id": 566, "name": "Online Şan Dersi" },
        { "id": 567, "name": "Online Türkçe Özel Ders" },
        { "id": 568, "name": "Online TYT Matematik Özel Ders" },
        { "id": 569, "name": "Online YKS Koçu" },
        { "id": 570, "name": "Online Yoga Dersi" },
        { "id": 571, "name": "Ortaokul Matematik Özel Ders" },
        { "id": 572, "name": "Otizm Özel Ders" },
        { "id": 573, "name": "Özel Ders" },
        { "id": 574, "name": "Özel Eğitim" },
        { "id": 575, "name": "Pasta" },
        { "id": 576, "name": "Paten Dersi" },
        { "id": 577, "name": "Personal Trainer" },
        { "id": 578, "name": "Pilates Dersi" },
        { "id": 579, "name": "Piyano Dersi" },
        { "id": 580, "name": "Reformer Pilates Dersi" },
        { "id": 581, "name": "Resim Kursu" },
        { "id": 582, "name": "Resim Özel Ders" },
        { "id": 583, "name": "Rusça Özel Ders" },
        { "id": 584, "name": "Şan Dersi" },
        { "id": 585, "name": "Satranç Özel Ders" },
        { "id": 586, "name": "Sınıf Öğretmeni" },
        { "id": 587, "name": "Spor Koçu" },
        { "id": 588, "name": "Sürücü Kursu" },
        { "id": 589, "name": "Sürüş Eğitimi" },
        { "id": 590, "name": "Tenis Dersi" },
        { "id": 591, "name": "Türkçe Özel Ders" },
        { "id": 592, "name": "TYT Matematik Özel Ders" },
        { "id": 593, "name": "Üniversite Fizik Özel Ders" },
        { "id": 594, "name": "Üniversite Matematik Özel Ders" },
        { "id": 595, "name": "Yan Flüt Dersi" },
        { "id": 596, "name": "Yazılım Özel Ders" },
        { "id": 597, "name": "Yetişkin Yüzme Dersi" },
        { "id": 598, "name": "Yoga Dersi" },
        { "id": 599, "name": "Yüzme Dersi" },
        { "id": 600, "name": "Zeybek Dersi" }
      ]
    },
    {
      "id": 6,
      "name": "Sağlık ve Güzellik Hizmetleri",
      "services": [
          { "id": 601, "name": "Aile Danışmanı" },
          { "id": 602, "name": "Aile Dizimi" },
          { "id": 603, "name": "Aile Terapisi" },
          { "id": 604, "name": "Bağımlılık Psikolog" },
          { "id": 605, "name": "Bilişsel Davranışçı Terapi" },
          { "id": 606, "name": "Bioenerji Uzmanı" },
          { "id": 607, "name": "Çift Terapisi" },
          { "id": 608, "name": "Cilt Bakımı" },
          { "id": 609, "name": "Cinsel Terapi" },
          { "id": 610, "name": "Çocuk Bakımı" },
          { "id": 611, "name": "Çocuk Bakımı ve Ev Yardımcısı" },
          { "id": 612, "name": "Çocuk Oyun Grubu" },
          { "id": 613, "name": "Çocuk Psikoloğu" },
          { "id": 614, "name": "Dil ve Konuşma Terapisi" },
          { "id": 615, "name": "Diyetisyen" },
          { "id": 616, "name": "Doğum Haritası Hesaplama" },
          { "id": 617, "name": "Dövme Tattoo" },
          { "id": 618, "name": "EMDR Terapisi" },
          { "id": 619, "name": "Emzirme Danışmanı" },
          { "id": 620, "name": "Epilasyon" },
          { "id": 621, "name": "Ergoterapi" },
          { "id": 622, "name": "Erkek Epilasyon" },
          { "id": 623, "name": "Ev Yardımcısı" },
          { "id": 624, "name": "Evde Bakım Hizmetleri" },
          { "id": 625, "name": "Evde Fizik Tedavi" },
          { "id": 626, "name": "Evde Hasta Bakımı" },
          { "id": 627, "name": "Evde Hemşire" },
          { "id": 628, "name": "Evde Serum Takma" },
          { "id": 629, "name": "Evde Yaşlı Bakımı" },
          { "id": 630, "name": "Evlilik Terapisi" },
          { "id": 631, "name": "Fal Bakma" },
          { "id": 632, "name": "Fizyoterapist" },
          { "id": 633, "name": "Gelin Makyajı" },
          { "id": 634, "name": "Gelin Saçı" },
          { "id": 635, "name": "Hasta Bakımı" },
          { "id": 636, "name": "Hastane Refakatçisi" },
          { "id": 637, "name": "Hemşire" },
          { "id": 638, "name": "Hipnoterapi" },
          { "id": 639, "name": "İlişki Terapisi" },
          { "id": 640, "name": "İngilizce Oyun Ablası" },
          { "id": 641, "name": "İpek Kirpik" },
          { "id": 642, "name": "Jel Tırnak" },
          { "id": 643, "name": "Kalıcı Oje Yapımı" },
          { "id": 644, "name": "Kayropraktik" },
          { "id": 645, "name": "Klinik Psikolog" },
          { "id": 646, "name": "Kuaför" },
          { "id": 647, "name": "Kupa Terapi" },
          { "id": 648, "name": "Kurşun Döktürme" },
          { "id": 649, "name": "Makyaj" },
          { "id": 650, "name": "Manuel Lenf Drenajı" },
          { "id": 651, "name": "Manuel Terapi" },
          { "id": 652, "name": "Masaj (Erkek İçin)" },
          { "id": 653, "name": "Medyum" },
          { "id": 654, "name": "Mikro Kaynak" },
          { "id": 655, "name": "Moxo Testi" },
          { "id": 656, "name": "Nişan Makyajı" },
          { "id": 657, "name": "Online Aile Terapisi" },
          { "id": 658, "name": "Online Bağımlılık Psikolog" },
          { "id": 659, "name": "Online Çift Terapisi" },
          { "id": 660, "name": "Online Cinsel Terapi" },
          { "id": 661, "name": "Online Çocuk Psikoloğu" },
          { "id": 662, "name": "Online Dil ve Konuşma Terapisi" },
          { "id": 663, "name": "Online Diyetisyen" },
          { "id": 664, "name": "Online Ergen Psikoloğu" },
          { "id": 665, "name": "Online Evlilik Terapisi" },
          { "id": 666, "name": "Online Kahve Falı" },
          { "id": 667, "name": "Online Pedagog" },
          { "id": 668, "name": "Online Psikolog" },
          { "id": 669, "name": "Online Psikolojik Danışman" },
          { "id": 670, "name": "Online Psikoterapi" },
          { "id": 671, "name": "Online Tarot Falı" },
          { "id": 672, "name": "Online Terapi" },
          { "id": 673, "name": "Online Yaşam Koçu" },
          { "id": 674, "name": "Oyun Ablası" },
          { "id": 675, "name": "Oyun Terapisi" },
          { "id": 676, "name": "Özel Gün Makyajı" },
          { "id": 677, "name": "Pedagog" },
          { "id": 678, "name": "Personal Trainer" },
          { "id": 679, "name": "Pilates Dersi" },
          { "id": 680, "name": "Protez Tırnak Yapımı" },
          { "id": 681, "name": "Psikolog" },
          { "id": 682, "name": "Psikolojik Danışman" },
          { "id": 683, "name": "Psikoterapi" },
          { "id": 684, "name": "Saatlik Çocuk Bakımı" },
          { "id": 685, "name": "Saç Kaynağı" },
          { "id": 686, "name": "Saç ve Makyaj" },
          { "id": 687, "name": "Theta Healing" },
          { "id": 688, "name": "Uzman Psikolog" },
          { "id": 689, "name": "Yaşam Koçu" },
          { "id": 690, "name": "Yaşlı Bakımı" },
          { "id": 691, "name": "Yıldızname Bakma" }
      ]
  },
  {
      "id": 7,
      "name": "Organizasyon Hizmetleri",
      "services": [
        { "id": 701, "name": "Abiye Dikimi" },
        { "id": 702, "name": "Açılış Organizasyonu" },
        { "id": 703, "name": "Animatör" },
        { "id": 704, "name": "Bando Takımı" },
        { "id": 705, "name": "Butik Pasta" },
        { "id": 706, "name": "Canlı Müzik" },
        { "id": 707, "name": "Catering" },
        { "id": 708, "name": "Çiçekçi" },
        { "id": 709, "name": "Dansöz Kiralama" },
        { "id": 710, "name": "Davul Zurnacı Kiralama" },
        { "id": 711, "name": "Dini Nikah Kıyma" },
        { "id": 712, "name": "DJ" },
        { "id": 713, "name": "Doğum Günü Catering" },
        { "id": 714, "name": "Doğum Günü Mekanları" },
        { "id": 715, "name": "Doğum Günü Organizasyonu" },
        { "id": 716, "name": "Doğum Günü Pastası" },
        { "id": 717, "name": "Düğün Organizasyon" },
        { "id": 718, "name": "Düğün Orkestrası" },
        { "id": 719, "name": "Evde Nişan Organizasyonu" },
        { "id": 720, "name": "Evde Söz Organizasyonu" },
        { "id": 721, "name": "Evlilik Teklifi Organizasyon" },
        { "id": 722, "name": "Gelin Alma Bandosu" },
        { "id": 723, "name": "Gelin Arabası Kiralama" },
        { "id": 724, "name": "Gelin Arabası Süsleme" },
        { "id": 725, "name": "Gelinlik" },
        { "id": 726, "name": "Gelinlik Dikimi" },
        { "id": 727, "name": "Gelinlik Kiralama" },
        { "id": 728, "name": "Hastane Odası Süsleme" },
        { "id": 729, "name": "İftar Yemeği Catering" },
        { "id": 730, "name": "Kemancı Kiralama" },
        { "id": 731, "name": "Kına Organizasyon" },
        { "id": 732, "name": "Kiralık Kaleci" },
        { "id": 733, "name": "Kokteyl Catering" },
        { "id": 734, "name": "Lokma Döktürme" },
        { "id": 735, "name": "Masa Sandalye Kiralama" },
        { "id": 736, "name": "Mevlüt Okuma" },
        { "id": 737, "name": "Mevlüt Pilavı" },
        { "id": 738, "name": "Mevlüt Yemeği" },
        { "id": 739, "name": "Nikah Şekeri" },
        { "id": 740, "name": "Nişan Hediyelik" },
        { "id": 741, "name": "Nişan İkramlıkları Catering" },
        { "id": 742, "name": "Nişan Mekanları" },
        { "id": 743, "name": "Nişan Menüsü Catering" },
        { "id": 744, "name": "Nişan Organizasyon" },
        { "id": 745, "name": "Organizasyon" },
        { "id": 746, "name": "Palyaço" },
        { "id": 747, "name": "Pasta" },
        { "id": 748, "name": "Plastik Masa Sandalye Kiralama" },
        { "id": 749, "name": "Ses Sistemi Kiralama" },
        { "id": 750, "name": "Sihirbaz" },
        { "id": 751, "name": "Tabldot Yemek" },
        { "id": 752, "name": "Tekne Kiralama" },
        { "id": 753, "name": "Temsili Nikah Memuru" },
        { "id": 754, "name": "Yat Kiralama" },
        { "id": 755, "name": "Yatta Evlilik Teklifi" }
      ]
    },
    {
      "id": 8,
      "name": "Fotoğraf ve Video",
      "services": [
        { "id": 801, "name": "Bebek Fotoğrafçısı" },
        { "id": 802, "name": "Dış Çekim Fotoğraf" },
        { "id": 803, "name": "Doğum Günü Fotoğrafçısı" },
        { "id": 804, "name": "Drone Çekimi" },
        { "id": 805, "name": "Drone Fotoğraf ve Video" },
        { "id": 806, "name": "Düğün Fotoğrafçısı" },
        { "id": 807, "name": "Düğün Video Çekimi" },
        { "id": 808, "name": "E Ticaret Fotoğraf Çekimi" },
        { "id": 809, "name": "Fotoğrafçı" },
        { "id": 810, "name": "Instagram İçin Fotoğraf Çekimi" },
        { "id": 811, "name": "Kameraman" },
        { "id": 812, "name": "Klip Çekimi" },
        { "id": 813, "name": "Mekan Fotoğrafçısı" },
        { "id": 814, "name": "Nikah Fotoğrafçısı" },
        { "id": 815, "name": "Nişan Fotoğrafçısı" },
        { "id": 816, "name": "Sosyal Medya İçin Fotoğraf Çekimi" },
        { "id": 817, "name": "Sosyal Medya Video Çekimi" },
        { "id": 818, "name": "Söz Fotoğraf Çekimi" },
        { "id": 819, "name": "Tanıtım Filmi Çekimi" },
        { "id": 820, "name": "Ürün Fotoğraf Çekimi" },
        { "id": 821, "name": "Video Çekimi" },
        { "id": 822, "name": "Video Editörü" },
        { "id": 823, "name": "Video Kurgu" }
      ]
    },
    {
      "id": 9,
      "name": "Dijital ve Kurumsal",
      "services": [
        { "id": 901, "name": "3D Animasyon Yapımı" },
        { "id": 902, "name": "3D Baskı" },
        { "id": 903, "name": "ABD Vize Danışmanlık" },
        { "id": 904, "name": "Afiş Tasarım" },
        { "id": 905, "name": "Almanca Çeviri" },
        { "id": 906, "name": "Almanca Yeminli Tercüme" },
        { "id": 907, "name": "Almanya Vize Danışmanlık" },
        { "id": 908, "name": "Ambalaj Tasarım" },
        { "id": 909, "name": "Android Uygulama Geliştirme" },
        { "id": 910, "name": "Apartman Yönetimi" },
        { "id": 911, "name": "Astroloji Danışmanlığı" },
        { "id": 912, "name": "AutoCAD Çizim" },
        { "id": 913, "name": "Broşür Baskı" },
        { "id": 914, "name": "Broşür Dağıtım" },
        { "id": 915, "name": "Broşür Tasarım" },
        { "id": 916, "name": "Cam Giydirme" },
        { "id": 917, "name": "CV Hazırlama Danışmanlığı" },
        { "id": 918, "name": "Davetiye Baskı" },
        { "id": 919, "name": "Dijital Baskı" },
        { "id": 920, "name": "Dijital Pazarlama" },
        { "id": 921, "name": "Duvara Resim Yapma" },
        { "id": 922, "name": "E Ticaret Danışmanı" },
        { "id": 923, "name": "Elbise Dikimi" },
        { "id": 924, "name": "Elektronik Devre Tasarımı" },
        { "id": 925, "name": "Emlak Satış Danışmanı" },
        { "id": 926, "name": "Etsy Danışmanı" },
        { "id": 927, "name": "Fason Kozmetik Üretimi" },
        { "id": 928, "name": "Fransa Vize Danışmanlık" },
        { "id": 929, "name": "Freelance Yazılımcı" },
        { "id": 930, "name": "Fuar Hostesi" },
        { "id": 931, "name": "Google Reklam Yönetimi" },
        { "id": 932, "name": "Graffiti" },
        { "id": 933, "name": "Grafik Tasarım" },
        { "id": 934, "name": "Gümrük Müşaviri" },
        { "id": 935, "name": "İç Mimar Danışmanlık" },
        { "id": 936, "name": "İllüstrasyon Çizim" },
        { "id": 937, "name": "İngilizce Çeviri" },
        { "id": 938, "name": "İngilizce Yeminli Tercüme" },
        { "id": 939, "name": "İngiltere Vize Danışmanlık" },
        { "id": 940, "name": "Instagram Hesap Kurtarma" },
        { "id": 941, "name": "İş Sağlığı ve Güvenliği" },
        { "id": 942, "name": "Işıklı Tabela" },
        { "id": 943, "name": "Kadın Manken" },
        { "id": 944, "name": "Karakalem Çizim" },
        { "id": 945, "name": "Kartvizit Baskı" },
        { "id": 946, "name": "Kartvizit Tasarımı" },
        { "id": 947, "name": "Katalog Tasarımı" },
        { "id": 948, "name": "Kitap Baskı" },
        { "id": 949, "name": "KOSGEB Danışmanlık" },
        { "id": 950, "name": "Kurumsal Logo Tasarımı" },
        { "id": 951, "name": "Kutu Harf Tabela" },
        { "id": 952, "name": "Limited Şirket Kurma" },
        { "id": 953, "name": "Logo Tasarım" },
        { "id": 954, "name": "Marka Tescil" },
        { "id": 955, "name": "Metin Yazarı" },
        { "id": 956, "name": "Mevcut Web Sitesi Düzenleme" },
        { "id": 957, "name": "Mobil Uygulama Geliştirme" },
        { "id": 958, "name": "Moda Tasarım" },
        { "id": 959, "name": "Müzik Prodüksiyon" },
        { "id": 960, "name": "Ön Muhasebe" },
        { "id": 961, "name": "Osmanlıca Çeviri" },
        { "id": 962, "name": "Oyun Programlama" },
        { "id": 963, "name": "Özel Koruma" },
        { "id": 964, "name": "Özel Sağlık Sigortası" },
        { "id": 965, "name": "Photoshop Uzmanı" },
        { "id": 966, "name": "Reklam Ajansı" },
        { "id": 967, "name": "Ressam" },
        { "id": 968, "name": "Rusça Çeviri" },
        { "id": 969, "name": "Şahıs Şirketi Kurma" },
        { "id": 970, "name": "SEO Hizmeti" },
        { "id": 971, "name": "SEO Uyumlu Makale Yazımı" },
        { "id": 972, "name": "Seslendirme" },
        { "id": 973, "name": "SGK Danışmanlık" },
        { "id": 974, "name": "Sigorta Acentesi" },
        { "id": 975, "name": "Şirket Kurma" },
        { "id": 976, "name": "Sosyal Medya Danışmanlığı" },
        { "id": 977, "name": "Sosyal Medya Tasarım" },
        { "id": 978, "name": "Sosyal Medya Uzmanı" },
        { "id": 979, "name": "Sosyal Medya Yönetimi ve Danışmanlığı" },
        { "id": 980, "name": "SPSS Analizi" },
        { "id": 981, "name": "Stüdyo Kayıt" },
        { "id": 982, "name": "Sunum Hazırlama" },
        { "id": 983, "name": "Sweatshirt İmalatı" },
        { "id": 984, "name": "Tabela" },
        { "id": 985, "name": "Tercüme" },
        { "id": 986, "name": "Terzi" },
        { "id": 987, "name": "Tişört Baskı" },
        { "id": 988, "name": "Tişört İmalatı" },
        { "id": 989, "name": "Tişört Tasarım" },
        { "id": 990, "name": "Trafik Sigortası" },
        { "id": 991, "name": "Vize Başvurusu" },
        { "id": 992, "name": "Web Site Yapımı" },
        { "id": 993, "name": "Web Tasarım" },
        { "id": 994, "name": "Web Tasarım Programlama" },
        { "id": 995, "name": "Web Yazılım" },
        { "id": 996, "name": "Website Design" },
        { "id": 997, "name": "Yazılım Geliştirme" },
        { "id": 998, "name": "YKS Tercih Danışmanı" },
        { "id": 999, "name": "Yunanistan Vize Danışmanlık" }
      ]
    },{
      "id": 10,
      "name": "Evcil Hayvanlar",
      "services": [
        { "id": 701, "name": "Evde Kedi Bakımı" },
        { "id": 702, "name": "Kedi Bakımı" },
        { "id": 703, "name": "Kedi Oteli" },
        { "id": 704, "name": "Kedi Traşı" },
        { "id": 705, "name": "Köpek Eğitimi" },
        { "id": 706, "name": "Köpek Gezdirme" },
        { "id": 707, "name": "Köpek Kuaförü" },
        { "id": 708, "name": "Köpek Oteli" },
        { "id": 709, "name": "Köpek Pansiyonu" },
        { "id": 710, "name": "Köpek Traşı" },
        { "id": 711, "name": "Pet Kuaförü" }
      ]
    },
    {
      "id": 11,
      "name": "Oto ve Araç",
      "services": [
        { "id": 701, "name": "Araç Bakım" },
        { "id": 702, "name": "Araç Folyo Kaplama" },
        { "id": 703, "name": "Araç Kaplama" },
        { "id": 704, "name": "Araç Koltuk Temizleme" },
        { "id": 705, "name": "Araç PPF Kaplama" },
        { "id": 706, "name": "Araç Seramik Kaplama" },
        { "id": 707, "name": "Balata Değişimi" },
        { "id": 708, "name": "Baskı Balata Değişimi" },
        { "id": 709, "name": "Boyasız Göçük Düzeltme" },
        { "id": 710, "name": "Cam Filmi" },
        { "id": 711, "name": "Detaylı Araç Temizliği" },
        { "id": 712, "name": "Lastikçi" },
        { "id": 713, "name": "Motor Contası Değişimi" },
        { "id": 714, "name": "Motor Tamiri" },
        { "id": 715, "name": "Motor Yağ Değişimi" },
        { "id": 716, "name": "Oto Boya" },
        { "id": 717, "name": "Oto Cam Filmi" },
        { "id": 718, "name": "Oto Ekspertiz" },
        { "id": 719, "name": "Oto Elektrik" },
        { "id": 720, "name": "Oto Kaporta Boya" },
        { "id": 721, "name": "Oto Klima Tamiri" },
        { "id": 722, "name": "Oto Kuaför" },
        { "id": 723, "name": "Oto Lastik" },
        { "id": 724, "name": "Oto Tamir" },
        { "id": 725, "name": "Pasta Cila" },
        { "id": 726, "name": "Seramik Kaplama" },
        { "id": 727, "name": "Triger Seti Değişimi" }
      ]
    },
    {
      "id": 12,
      "name": "Diğer",
      "services": [
        { "id": 601, "name": "3D Mimari Modelleme" },
        { "id": 602, "name": "3D Render" },
        { "id": 603, "name": "3D Ürün Modelleme" },
        { "id": 604, "name": "Ankastre Ocak Mermer Kesimi" },
        { "id": 605, "name": "Betonarme Ev Yapımı" },
        { "id": 606, "name": "Çamaşır Makinesi Su Kaçırıyor" },
        { "id": 607, "name": "Doğum Haritası Yorumlama" },
        { "id": 608, "name": "Duşakabin Montaj Dahil" },
        { "id": 609, "name": "Duvar Boyama" },
        { "id": 610, "name": "Emlak Drone Çekimi" },
        { "id": 611, "name": "Emlakçı" },
        { "id": 612, "name": "Etsy Mağaza Açma" },
        { "id": 613, "name": "Ev Yapımı" },
        { "id": 614, "name": "Fasıl Ekibi" },
        { "id": 615, "name": "Finansal Danışman" },
        { "id": 616, "name": "Gayrimenkul Danışmanı" },
        { "id": 617, "name": "Gayrimenkul Değerleme" },
        { "id": 618, "name": "Google Ads Uzmanı" },
        { "id": 619, "name": "Güneş Enerjisi Su Isıtma" },
        { "id": 620, "name": "Güneş Paneli" },
        { "id": 621, "name": "İmam Nikahı Kıyma" },
        { "id": 622, "name": "Instagram Reklam Yönetimi" },
        { "id": 623, "name": "İnternet Sitesi Oluşturma" },
        { "id": 624, "name": "Kamera Kurulum" },
        { "id": 625, "name": "Karavan Kiralama" },
        { "id": 626, "name": "LGS Hazırlık" },
        { "id": 627, "name": "Metafizik Uzmanı" },
        { "id": 628, "name": "Online Aile Danışmanı" },
        { "id": 629, "name": "Online Ön Muhasebe" },
        { "id": 630, "name": "Ot Biçme" },
        { "id": 631, "name": "Özel Dedektif" },
        { "id": 632, "name": "Pasta" },
        { "id": 633, "name": "Playstation Kiralama" },
        { "id": 634, "name": "Proje Yazma" },
        { "id": 635, "name": "Sandalye Kaplama" },
        { "id": 636, "name": "Site Yönetimi" },
        { "id": 637, "name": "Sosyal Medya Ajansı" },
        { "id": 638, "name": "Sözleşme Hazırlama" },
        { "id": 639, "name": "Tatlıcı" },
        { "id": 640, "name": "Tente" },
        { "id": 641, "name": "Toprak Dolgu" },
        { "id": 642, "name": "Trio Müzik Grubu" },
        { "id": 643, "name": "Vito Gelin Arabası Kiralama" },
        { "id": 644, "name": "Yabancı Çalışma İzni" },
        { "id": 645, "name": "Yağlı Boya Tablo Yapımı" },
        { "id": 646, "name": "Yatak Odası Takımı Boyama" },
        { "id": 647, "name": "Yazılımcı" },
        { "id": 648, "name": "YKS Koçu" },
        { "id": 649, "name": "Yüklük Dolap Yapımı" }
      ]
    }
  ]