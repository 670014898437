import { Routes, Route } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import PrivateRoute from "../routers/PrivateRoute";
import Enterance from "../pages/Enterance/Enterance";
import FindJob from "../pages/FindJob/FindJob";
import PostJob from "../pages/PostJob/PostJob";
import Profile from "../pages/Profile/Profile";

function Routers() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        
        <Route
          path="/enterance"
          element={
            <PrivateRoute>
              <Enterance />
            </PrivateRoute>
          }
        />
        
        <Route
          path="/find-job"
          element={
            <PrivateRoute>
              <FindJob />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        
        <Route
          path="/post-job"
          element={
            <PrivateRoute>
              <PostJob />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default Routers;
