import React from "react";
import "./Profile.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MyJobs from "../../components/MyJobs/MyJobs";

const Profile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { t } = useTranslation("pages"); 

  const defaultCoverPicture = `${baseUrl}/uploads/cover.png`;
  const defaultProfilePicture = `${baseUrl}/uploads/profile.png`;

  const handleLogout = () => {
    dispatch(logout());
  };

  const coverPictureUrl = user.coverPicture ? `${baseUrl}/uploads/${user.coverPicture}` : defaultCoverPicture;
  const profilePictureUrl = user.profilePicture ? `${baseUrl}/uploads/${user.profilePicture}` : defaultProfilePicture;

  return (
    <div className="profile-container">
      <div className="cover-picture">
        <img
          src={coverPictureUrl}
          alt={t("profile.coverPictureAlt")}
          className="cover-img"
        />
      </div>
      <div className="profile-card">
        <div className="profile-picture">
          <img
            src={profilePictureUrl}
            alt={t("profile.profilePictureAlt")}
            className="profile-img"
          />
        </div>
        <h2>{user.fullName}</h2>
        <p className="email">{user.email}</p>
        <div className="profile-details">
          <div className="profile-field">
            <span className="label">{t("profile.masterUserLabel")}</span>
            <span className="value">{user.isMaster ? t("profile.yes") : t("profile.no")}</span>
          </div>
          <div className="profile-field">
            <span className="label">{t("profile.accountCreatedLabel")}</span>
            <span className="value">
              {new Date(user.createdAt).toLocaleDateString()}
            </span>
          </div>
        </div>
        <div className="action-buttons">
          <Link to="/update-profile" className="nbutton edit-button">
            {t("profile.editProfileButton")}
          </Link>
          <Link className="nbutton logout-button" onClick={handleLogout}>
            {t("profile.logoutButton")}
          </Link>
        </div>
      </div>

      <h2><MyJobs/></h2>
    </div>
  );
};

export default Profile;
