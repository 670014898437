import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enPages from "./locales/en/pages.json";
import trPages from "./locales/tr/pages.json";
import enComponents from "./locales/en/components.json"
import trComponents from "./locales/tr/components.json"

i18n.use(initReactI18next).init({
  resources: {
    en: { pages: enPages, components: enComponents },
    tr: { pages: trPages, components: trComponents },
  },
  lng: "tr",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

export default i18n;
